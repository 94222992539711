import "./tribe.css"
import { useNavigate } from "react-router-dom";
const Tribe = () => {
  const navigate = useNavigate();

  return (
    <div className="tribe-container">
      <div className="tribe-content">
        <div className="tribe-image-container">
          <img src="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/tribe+image+copy.webp" alt="One Tribe One Drive" className="tribe-image" />
        </div>
        <div className="tribe-text-content">
          <h1 className="tribe-title">THE TRIBE</h1>
          <h2 className="tribe-subtitle">
            Over <span className="highlight">2 mn+ runners</span>
          </h2>
          <p className="tribe-description">
            strong, the Devilslayer Tribe is more than just a community—it's a movement. United by grit and the drive to
            conquer 15 epic obstacles, Devilslayers push limits, break barriers, and chase greatness together. Devils
            Circuit isn't just about crossing the finish line—it's about forging lifelong bonds with those who share
            your fire. A tribe of supporters, challengers, and unstoppable warriors, Devilslayers uplift, inspire, and
            drive each other toward excellence. When you run with us, you're not just racing—you're joining a legacy of
            resilience and triumph!
          </p>
          <button className="know-more-button-new"onClick={() => navigate("/community")}>Know more!</button>
        </div>
      </div>
    </div>
  )
}

export default Tribe

