import React from "react";
import "./citypage.css";
import Playground from "../homepage/playground";
import band from "../../assets/homepage/newband.png";
import { Link } from "react-router-dom";
import NearMeIcon from "@mui/icons-material/NearMe";
import useUtmParams from "../../utils/useUtmParams";

const cityPageData = {
  cityData: [
    {
      cityName: "Ahmedabad",
      eventName: "Maruti Suzuki Arena Devils Circuit Ahmedabad",
      eventDate: "November 10, 2024",
      backgroundImage:
        "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/1_3_11zon.webp",
      venue: "Adani Shantigram Township, Ahmedabad",
      buttonLink:"https://getmybib.com/166/register",
      showRegister: false,
      showJunior: false,
      showDirection: false,
      lat: "",
      lng: "",
    },
    {
      cityName: "Indore",
      eventName: "Maruti Suzuki Arena Devils Circuit Indore",
      eventDate: "September 29, 2024",
      backgroundImage:
        "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/2_1_11zon.webp",
      venue:
        "Omaxe Supercorridor QQRM+85P, Indore, Budhaniya, Madhya Pradesh 453112",
      buttonLink:"https://getmybib.com/165/register",
      showRegister: false,
      showJunior: false,
      showDirection: false,
      lat: "",
      lng: "",
    },
    {
      cityName: "Pune",
      eventName: "Maruti Suzuki Arena Devils Circuit Pune",
      eventDate: "November 24, 2024",
      backgroundImage:
        "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/3_2_11zon.webp",
      venue: "Hiranandani-Krisala Township, Darumbre, Pune 410506",
      buttonLink:"https://getmybib.com/164/register",
      showRegister: false,
      showJunior: false,
      showDirection: false,
      lat: "",
      lng: "",
    },
    {
      cityName: "Mumbai",
      eventName: "Maruti Suzuki Arena Devils Circuit Mumbai",
      eventDate: "October 20, 2024",
      backgroundImage:
        "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/4_4_11zon.webp",
      venue:
        "Raheja district, Sales Gallery, Juinagar, MIDC Industrial Area, Juinagar, Navi Mumbai, Maharashtra 400705",
      buttonLink:"https://getmybib.com/163/register",
      showRegister: false,
      showJunior: false,
      showDirection: false,
      lat: "",
      lng: "",
    },
    {
      cityName: "Hyderabad",
      eventName: "Maruti Suzuki Arena Devils Circuit Hyderabad",
      eventDate: "January 5, 2025",
      backgroundImage:
        "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/5_10_11zon.webp",
      venue: "GMR Arena hyderabad",
      buttonLink:"https://getmybib.com/162/register",
      showRegister: false,
      showJunior: false,
      showDirection: false,
      lat: 17.24408564454225,
      lng: 78.45387260099365,
    },
    {
      cityName: "Bengaluru",
      eventName: "Maruti Suzuki Arena Devils Circuit Bengaluru",
      eventDate: "December 15, 2024",
      backgroundImage:
        "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/6_6_11zon.webp",
      venue:
        "Century Whitefield, Bengaluru - 560049, Karnataka",
      buttonLink:"https://getmybib.com/161/register",
      showRegister: false,
      showJunior: true,
      showDirection: false,
      lat: 13.052071337277441,
      lng: 77.74758891540324,
    },
    {
      cityName: "Kochi",
      eventName: "Maruti Suzuki Arena Devils Circuit Kochi",
      eventDate: "January 19, 2025",
      backgroundImage:
        "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/7_7_11zon.webp",
      venue: "Opp.Ezhumavil Mana, Padamukal, Palachuvadu, Kakanad P.O, Kerala 682030",
      buttonLink:"https://getmybib.com/160/register",
      showRegister: false,
      showJunior: false,
      showDirection: false,
      lat: "9.993885501430839",
      lng: "76.33591961629963",
    },
    {
      cityName: "Chennai",
      eventName: "Maruti Suzuki Arena Devils Circuit Chennai",
      eventDate: "February 2, 2025",
      backgroundImage:
        "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/8_5_11zon.webp",
      venue:
        "Pragnya Eden Park. A 92 Acre Township, M.R. Radha Main Road, Next to SIPCOT IT Park, Siruseri, OMR, Chennai - 603 103, Tamil Nadu, INDIA",
      buttonLink:"https://getmybib.com/159/register",
      showRegister: false,
      showJunior: false,
      showDirection: false,
      lat: "12.823844483654751",
      lng: "80.1986956880927", 
    },
    {
      cityName: "Chandigarh",
      eventName: "Maruti Suzuki Arena Devils Circuit New Chandigarh",
      eventDate: "February 16, 2025",
      backgroundImage:
        "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/9_8_11zon.webp",
      venue:
        "World Street Downtown Omaxe New Chandigarh, Punjab 140901",
      buttonLink:"https://getmybib.com/158/register",
      showRegister: false,
      showJunior: false,
      showDirection: false,
      lat: "30.82247053627796",
      lng: "76.73280920313803",
    },
    {
      cityName: "Delhi",
      eventName: "Maruti Suzuki Arena Devils Circuit Delhi NCR",
      eventDate: "March 9, 2025",
      backgroundImage:
        "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/10_9_11zon.webp",
      venue: "The Omaxe State, Sector 19B, Dwarka, New Delhi, Delhi, 110075",
      buttonLink:"https://getmybib.com/157/register",
      showRegister: true,
      showJunior: true,
      showDirection: true,
      lat: "28.57218286901703",
      lng: "77.03919613086389"
    },
  ],
};

const CityPage = (props) => {
  const city = cityPageData.cityData.find(
    (city) => city.cityName === props.view
  );

  if (!city) {
    return <div>City not found</div>;
  }

  const para =
    "Gear up with a branded Jockey t-shirt and headband, stay energized with drinks, hydrate at stations, and proudly wear your finisher medal. Dominate the challenge, emerge victorious.";
  const handleCardClick = () => {
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${city.lat},${city.lng}`;
    window.open(googleMapsUrl, "_blank");
  };
    useUtmParams();

    const appendUtmParams = (url) => {
      const utmParams = ["utm_source", "utm_medium", "utm_campaign", "utm_content", "utm_term"]
        .map((key) => sessionStorage.getItem(key) && `${key}=${encodeURIComponent(sessionStorage.getItem(key))}`)
        .filter(Boolean)
        .join("&");
  
      return utmParams ? `${url}?${utmParams}` : url;
    };
  
    const handleRegisterNowClick = (url) => {
      const buttonLinkWithUtm = appendUtmParams(url);
      window.location.href = buttonLinkWithUtm;
    };
  return (
    <div>
      <div className="cityheading">{city.eventName}</div>
      <div className="citytopbanner">
        <div class="image_wrapper_city">
          <img src={city.backgroundImage} className="citybannerimage" alt="" />
          <div class="overlay"></div>
        </div>
        {/* <img src={city.backgroundImage} className="citybannerimage" /> */}
        <div className="aboutcity">
          <p className="cityLocation">
            <strong>Venue : </strong> {city.venue}
          </p>

          <p className="cityEventDate">
            <strong>Date :</strong> {city.eventDate}
          </p>
          <p className="cityname">
            <strong>Categories :</strong> Competitive, non competitive, master
            {city.showJunior ? ", Junior" : ""}
          </p>
          <div className="cityButton">
            {city.showRegister && (
              <button
              onClick={() => handleRegisterNowClick(city.buttonLink)}
              style={{ textDecoration: "none", border:"none" }}
                className="cityregisterbutton"
              >
                Register Now{" "}
              </button>
            )}
            {city.showDirection && (
              <p
                onClick={handleCardClick}
                style={{ color: "#ba1e24", padding: "8px", marginLeft: "10px" }}
                className="directionButton"
              >
                direction <NearMeIcon />
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="citydescription">
        <p className="citypara1"></p>
        <p className="citypara2">
          Maruti Suzuki Arena Devils Circuit is the Rockstar of Running Events:
          it is Asia's biggest Obstacle Race and the only running event in India
          to boost a community of over two million runners! But we believe our
          runners can do much more than conquer a rugged stretch of land,
          therefore, we have peppered our five-kilometre track with 15 crazy,
          fun obstacles to conquer before you reach the Finish Line isn't that
          cool
        </p>
        <p>
          When you join our Tribe of DevilSlayers, you will find so many runners
          who share your life's story. On the circuit, you will find strangers
          help you cross obstacles, and by your own virtue of goodness, you will
          help others out. Because, our end goal is the same: "We Help Each
          Other Become the Best Version of Ourselves." You will see the
          difference as soon as you are at the Start Line and the following
          excuses will cease to exist
        </p>
      </div>
      <Playground showJunior={city.showJunior} category={true} />
      {/* <div className="band_img_div_comp">
        <img src={band} alt="band" loading="lazy" />
      </div> */}
      {/* <div className="obstaclesection">
        <div className="headingandbutton">
          <div className="obstacleheading">
            <span className="cityobstaclenumber">15</span>{" "}
            <span className="cityobstacletext">obstacles</span>
          </div>
          <Link
            to={"/obstacles"}
            style={{ textDecoration: "none" }}
            className="seeallbutton"
          >
            SEE ALL
          </Link>
        </div>
        <div className="obstaclecardouterdiv">
          <div className="obstaclecard">
            <img src="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/ertg.webp" />
            <div className="cardobstaclename">Commando Crawl</div>
          </div>
          <div className="obstaclecard">
            <img src="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/Rectangle+484+(1).webp" />
            <div className="cardobstaclename">Rope Climb</div>
          </div>
          <div className="obstaclecard">
            <img src="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/sefref.webp" />
            <div className="cardobstaclename">The Summit</div>
          </div>
          <Link
            to={"/obstacles"}
            style={{ textDecoration: "none" }}
            className="seeallbuttonmobile"
          >
            SEE ALL
          </Link>
        </div>
        <div></div>
      </div> */}
    </div>
  );
};

export default CityPage;
