// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-25%);
  }
}

.banner_container {
  background-color: white;
  /* padding-top: 100px; */
  overflow: hidden;
}

.moving-banner-image {
  height: 50px;
  object-fit: contain;
}

.banner-scroll {
  animation: scroll linear infinite;
  width: max-content;
}`, "",{"version":3,"sources":["webpack://./src/components/newSeason/movingbanner.css"],"names":[],"mappings":"AAAA;EACE;IACE,wBAAwB;EAC1B;EACA;IACE,2BAA2B;EAC7B;AACF;;AAEA;EACE,uBAAuB;EACvB,wBAAwB;EACxB,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,iCAAiC;EACjC,kBAAkB;AACpB","sourcesContent":["@keyframes scroll {\n  0% {\n    transform: translateX(0);\n  }\n  100% {\n    transform: translateX(-25%);\n  }\n}\n\n.banner_container {\n  background-color: white;\n  /* padding-top: 100px; */\n  overflow: hidden;\n}\n\n.moving-banner-image {\n  height: 50px;\n  object-fit: contain;\n}\n\n.banner-scroll {\n  animation: scroll linear infinite;\n  width: max-content;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
