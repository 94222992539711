import { useEffect, useRef, useState } from "react"
import movingbanner from "../../assets/movingbanner.svg"
import "./movingbanner.css"

export default function MovingBanner({
  speed = 30,
  className = "",
}) {
  const containerRef = useRef(null)
  const [contentWidth, setContentWidth] = useState(0)

  useEffect(() => {
    const updateWidths = () => {
      if (containerRef.current) {
        const singleBannerWidth = containerRef.current.firstChild.firstChild.offsetWidth
        setContentWidth(singleBannerWidth)
      }
    }

    updateWidths()
    window.addEventListener("resize", updateWidths)

    return () => {
      window.removeEventListener("resize", updateWidths)
    }
  }, [])

  return (
    <div className={`banner_container ${className}`} ref={containerRef}>
      <div
        className="banner-scroll"
        style={{
          animationDuration: `${speed}s`
        }}
      >
        <img
          src={movingbanner}
          alt="Banner image"
          className="moving-banner-image"
        />
        <img
          src={movingbanner}
          alt="Banner image"
          className="moving-banner-image"
        />
        <img
          src={movingbanner}
          alt="Banner image"
          className="moving-banner-image"
        />
        <img
          src={movingbanner}
          alt="Banner image"
          className="moving-banner-image"
        />
      </div>
    </div>
  )
}
