"use client"
import "./slayercards.css"
import slayersImage from '../../assets/slayers.svg'
import { useState } from 'react'
import { useNavigate } from "react-router-dom";

const VolumeIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 5L6 9H2V15H6L11 19V5Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M15.54 8.46C16.4774 9.39764 17.0039 10.6692 17.0039 12C17.0039 13.3308 16.4774 14.6024 15.54 15.54" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M19.07 4.93C20.9447 6.80528 21.9979 9.34836 21.9979 12C21.9979 14.6516 20.9447 17.1947 19.07 19.07" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const MuteIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 5L6 9H2V15H6L11 19V5Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M23 9L17 15" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M17 9L23 15" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const PlayIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 3L19 12L5 21V3Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const PauseIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 4H10V20H6V4Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M14 4H18V20H14V4Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const SlayersCard = () => {
    const navigate = useNavigate();

  const [isMuted, setIsMuted] = useState(false);
  const [activeVideo, setActiveVideo] = useState(null);
  const [pausedStates, setPausedStates] = useState({});

  const videoUrls = [
    "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/ajay.mp4",
    "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/asia+toughest+race.mp4",
    "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/lady+emo+(1).mp4",
    "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/emotional.mp4"
  ];

  const handlePlayClick = (e, index) => {
    const card = e.currentTarget.closest('.card');
    if (!card) return;

    const image = card.querySelector('.card-img');
    const video = card.querySelector('.card-video');
    const initialPlayButton = card.querySelector('.play-button');
    const muteButton = card.querySelector('.muting-button');
    const playPauseButton = card.querySelector('.playing-pause-button');
    
    if (image && video && initialPlayButton && muteButton && playPauseButton) {
      image.style.display = 'none';
      video.style.display = 'block';
      initialPlayButton.style.display = 'none';
      muteButton.style.display = 'flex';
      playPauseButton.style.display = 'flex';
      video.play();
      setActiveVideo(video);
      setPausedStates(prev => ({ ...prev, [index]: false }));
    }
  };

  const handleCardHover = (e) => {
    if (e.target.closest('.muting-button') || e.target.closest('.playing-pause-button')) return;
    
    const allVideos = document.querySelectorAll('.card-video');
    allVideos.forEach((video, idx) => {
      video.pause();
      video.currentTime = 0;
      
      const card = video.closest('.card');
      if (!card) return;

      const image = card.querySelector('.card-img');
      const initialPlayButton = card.querySelector('.play-button');
      const muteButton = card.querySelector('.muting-button');
      const playPauseButton = card.querySelector('.playing-pause-button');
      
      if (image && initialPlayButton && muteButton && playPauseButton) {
        image.style.display = 'block';
        video.style.display = 'none';
        initialPlayButton.style.display = 'flex';
        muteButton.style.display = 'none';
        playPauseButton.style.display = 'none';
        setPausedStates(prev => ({ ...prev, [idx]: false }));
      }
    });
  };

  const handleVideoEnd = (e, index) => {
    const video = e.target;
    const card = video.closest('.card');
    if (!card) return;

    const image = card.querySelector('.card-img');
    const initialPlayButton = card.querySelector('.play-button');
    const muteButton = card.querySelector('.muting-button');
    const playPauseButton = card.querySelector('.playing-pause-button');
    
    if (image && initialPlayButton && muteButton && playPauseButton) {
      image.style.display = 'block';
      video.style.display = 'none';
      initialPlayButton.style.display = 'flex';
      muteButton.style.display = 'none';
      playPauseButton.style.display = 'none';
      setPausedStates(prev => ({ ...prev, [index]: false }));
    }
  };

  const toggleMute = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsMuted(!isMuted);
  };

  const togglePlayPause = (e, index) => {
    e.preventDefault();
    e.stopPropagation();
    const card = e.currentTarget.closest('.card');
    if (!card) return;

    const video = card.querySelector('.card-video');
    if (!video) return;
    
    if (video.paused) {
      video.play();
      setPausedStates(prev => ({ ...prev, [index]: false }));
    } else {
      video.pause();
      setPausedStates(prev => ({ ...prev, [index]: true }));
    }
  };

  return (
    <div className="slayers-container">
      <div className="slayers-header">
        <h1 className="slayers-title">
          <span className="slayer">Slayer</span> <span className="speaks">SPEAKS</span>
        </h1>
        <div className="testimonials-button" onClick={() => navigate("/community")}>Testimonials</div>
        <p className="slayers-subtitle">Let's hear why you need to register for the race</p>
      </div>
      <div className="cards-container">
        {videoUrls.map((videoUrl, index) => (
          <div className="card" key={index} onMouseEnter={handleCardHover}>
            <img 
              src={index === 0 
                ? "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/Testimonial_Thumbnail_1.webp"
                : index === 1
                ? "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/Testimonial_thumbnail_2.webp"
                : index === 2
                ? "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/Testimonial_Thumbnail_3.webp"
                : "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/Testimonial_Thumbnail_4.webp"
              } 
              alt="Devils Circuit" 
              className="card-img" 
            />
            <video
              className="card-video"
              src={videoUrl}
              playsInline
              muted={isMuted}
              onEnded={(e) => handleVideoEnd(e, index)}
              style={{ display: 'none' }}
            />
            <div 
              className="play-button" 
              onClick={(e) => handlePlayClick(e, index)}
            >
              <span>▶</span>
            </div>
            <button
              className="playing-pause-button"
              onClick={(e) => togglePlayPause(e, index)}
              style={{ display: 'none' }}
            >
              {pausedStates[index] ? <PlayIcon /> : <PauseIcon />}
            </button>
            <button
              className="muting-button"
              onClick={toggleMute}
              onMouseEnter={(e) => e.stopPropagation()}
              style={{ display: 'none' }}
            >
              {isMuted ? <MuteIcon /> : <VolumeIcon />}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SlayersCard;