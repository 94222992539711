import HomeBannerr from "./homebannerr";
import MovingBanner from "./movingbanner";
import "./newseason.css";
import SlayerCards from "./slayercards";
import Tribe from "./tribe";
import FindRaceCard from "./findracecard";
import GetCard from "./getcard";
import topvideo from "../../assets/homepage/newvideo.mp4";
import React, { useRef } from "react";
import Warrior from "./warrior";

const NewSeason = () => {
  const videoRef = useRef(null);
  const [isMuted, setIsMuted] = React.useState(true);

  const handleMuteToggle = () => {
    if (videoRef.current) {
      videoRef.current.muted = !videoRef.current.muted;
      setIsMuted(!isMuted);
    }
  };

  return (
    <div className="newseason-container">
      <div className="video-container" style={{ position: 'relative' }}>
        <video
          className="page-video-tag"
          ref={videoRef}
          controls={false}
          preload="true"
          loop={true}
          autoPlay={true}
          playsInline
          muted={isMuted}
        >
          <source src="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/compress+website+video.mp4" type="video/mp4" />
        </video>
        <button
          className="mute-button"
          onClick={handleMuteToggle}
          style={{
            position: 'absolute',
            bottom: '20px',
            right: '20px',
            padding: '8px',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            border: 'none',
            borderRadius: '50%',
            cursor: 'pointer',
            color: 'white',
          }}
        >
          {isMuted ? '🔇' : '🔊'}
        </button>
      </div>
      <MovingBanner />
      <HomeBannerr />
      <FindRaceCard />
      <GetCard />
      <Warrior />
      <Tribe />
      <SlayerCards />
    </div>
  );
};
export default NewSeason;
