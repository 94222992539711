import React, { useEffect } from "react";
import "./App.css";
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  useLocation,
} from "react-router-dom";
import FindYourRace from "../src/components/findYourRace/FindYourRace";
import Categories from "./components/categories/categories";
import HomePage from "./components/homepage/HomePage";
import MarketingAssociation from "./components/marketing/marketing";
import VenuePartnership from "./components/venue partnership/venue_partner";
import GYM from "./components/gym/gym";
import Photos from "./components/photos/photos";
import WhatsIncluded from "./components/whatsIncluded/whatsIncluded";
import Obstacles from "./components/obstacles/Obstacles";
import ContactUs from "./components/contactUs/ContactUs";
import Faq from "./components/faq/Faq";
import Franchise from "./components/franchise/Franchise";
import AboutUs from "./components/aboutUs/AboutUs";
import Village from "./components/thevillage/village";
import LeaderBoard from "./components/leaderboard/leaderboard";
import Header from "./components/common/header/Header";
import Footer from "./components/common/footer/Footer";
import TermsAndCondition from "./components/termsandcondition/TermsAndCondition";
import PrivactPolicy from "./components/privacypolicy/PrivacyPolicy";
import CityPage from "./components/cityPage/CityPage";
import Community from "./components/community/Community";
import Train from "./components/train/Train";
import CorporatePage from "./components/corporate/CorporatePage";
import PageNotFound from "./components/pagenotfound/PageNotFound";
import initializeGA4 from "./components/analytics";
import NewSeason from "./components/newSeason/newseason";
import NewCityPage from "./components/newcitypage/NewCityPage";

const AppLayout = () => {
  initializeGA4();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, [pathname]);

  return (
    <div className="app">
      <div className="header-main">
        <Header />
      </div>
      <Outlet />
      <Footer />
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <AppLayout />,
    children: [
      {
        path: "/",
        element: <HomePage />,
      },
      {
        path: "/find-your-race",
        element: <FindYourRace />,
      },
      {
        path: "/categories",
        element: <Categories view="competitive" />,
      },
      {
        path: "/categories/junior",
        element: <Categories view="junior" />,
      },
      {
        path: "/categories/non_comp",
        element: <Categories view="non_comp" />,
      },
      {
        path: "/categories/corporate",
        element: <Categories view="corporate" />,
      },
      {
        path: "/categories/master",
        element: <Categories view="master" />,
      },
      {
        path: "/marketing_association",
        element: <MarketingAssociation />,
      },
      {
        path: "/gym_partnership",
        element: <GYM />,
      },
      {
        path: "/venue_partnership",
        element: <VenuePartnership />,
      },
      {
        path: "/photos",
        element: <Photos />,
      },
      {
        path: "/whatsIncluded",
        element: <WhatsIncluded />,
      },
      {
        path: "/city-page/Indore",
        element: <CityPage view="Indore" />,
      },
      {
        path: "/city-page/Ahmedabad",
        element: <CityPage view="Ahmedabad" />,
      },
      {
        path: "/city-page/Pune",
        element: <CityPage view="Pune" />,
      },
      {
        path: "/city-page/Mumbai",
        element: <CityPage view="Mumbai" />,
      },
      {
        path: "/city-page/Hyderabad",
        element: <CityPage view="Hyderabad" />,
      },
      {
        path: "/city-page/Bengaluru",
        element: <CityPage view="Bengaluru" />,
      },
      {
        path: "/city-page/Kochi",
        element: <CityPage view="Kochi" />,
      },
      {
        path: "/city-page/Chandigarh",
        element: <CityPage view="Chandigarh" />,
      },
      {
        path: "/city-page/Chennai",
        element: <CityPage view="Chennai" />,
      },
      {
        path: "/city-page/Delhi",
        element: <CityPage view="Delhi" />,
      },
      {
        path: "/new-city-page/Bengaluru",
        element: <NewCityPage view="Bengaluru" />,
      },
      {
        path: "/new-city-page/Hyderabad",
        element: <NewCityPage view="Hyderabad" />,
      },
      {
        path: "/new-city-page/Chennai",
        element: <NewCityPage view="Chennai"/>,
      },
      {
        path: "/new-city-page/Kochi",
        element: <NewCityPage view="Kochi"/>,
      },
      {
        path: "/new-city-page/Mumbai",
        element: <NewCityPage view="Mumbai"/>,
      },
      {
        path: "/new-city-page/Indore",
        element: <NewCityPage view="Indore"/>,
      },
      {
        path: "/obstacles",
        element: <Obstacles />,
      },
      {
        path: "/contact-us",
        element: <ContactUs />,
      },
      {
        path: "/faq",
        element: <Faq />,
      },
      {
        path: "/franchise",
        element: <Franchise />,
      },
      {
        path: "/photos",
        element: <Photos />,
      },
      {
        path: "/about-us",
        element: <AboutUs />,
      },
      {
        path: "/village",
        element: <Village />,
      },
      {
        path: "/leaderboard",
        element: <LeaderBoard />,
      },
      {
        path: "/community",
        element: <Community />,
      },
      {
        path: "/terms-and-condition",
        element: <TermsAndCondition />,
      },
      {
        path: "/privacy-policy",
        element: <PrivactPolicy />,
      },
      {
        path: "/train",
        element: <Train />,
      },
      {
        path: "/corporate",
        element: <CorporatePage />,
      }
    ],
  },
  {
    path: "*",
    element: <PageNotFound />,
  },
]);

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
