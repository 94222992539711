import "./homebannerr.css";
import { useNavigate } from "react-router-dom";

const HomeBannerr = () => {
    const navigate = useNavigate();
  return (
    <div className="home-banner-container">
      <div className="banner-content">
        <h1 className="banner-heading">
          We heard you <br />
          <span className="banner-highlight">Loud & Clear!</span>
        </h1>
        <p className="banner-text">
          Our last season sold out in a flash, but guess what? We're back for another year, and it's going to be bigger,
          better, and way more fun! We're bringing the action to 12 cities with tougher challenges and wild obstacles.
          Get ready to take it to the next level!
        </p>
        <button className="banner-button"onClick={() => navigate("/find-your-race")}>Know more!</button>
      </div>
      <div className="banner-image">
        <img src="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/about+image+copy.webp" alt="Group of participants celebrating" />
      </div>
    </div>
  )
}

export default HomeBannerr