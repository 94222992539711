import React from "react";
import { useNavigate } from "react-router-dom";
import "../../components/findYourRace/findYourRaceCard.css";

const FindYourRaceCard = ({ data }) => {
  const navigate = useNavigate(); // Hook to programmatically navigate between routes

  // Function to handle Register Now button click with UTM parameters
  const handleRegisterNowClick = (url) => {
    const appendUtmParams = (url) => {
      const utmParams = [
        "utm_source",
        "utm_medium",
        "utm_campaign",
        "utm_content",
        "utm_term",
      ]
        .map(
          (key) =>
            sessionStorage.getItem(key) &&
            `${key}=${encodeURIComponent(sessionStorage.getItem(key))}`
        )
        .filter(Boolean)
        .join("&");

      return utmParams ? `${url}?${utmParams}` : url;
    };

    const buttonLinkWithUtm = appendUtmParams(url);
    window.location.href = buttonLinkWithUtm;
  };

  return (
    <>
      {data.map((item, index) => {
        return (
          <div className="findyourrace_card" key={index}>
            <div className="findyourrace_card_img_div">
              <img src={item.image} alt={`${item.city} event`} />
            </div>
            <div className="findyourrace_card_info_div">
              <p className="findyourrace_card_title">{item.city}</p>
              <p className="findyourrace_card_date">{item.date}</p>
              {item.showComingSoon && (
                <div className="findyourrace_card_btns">
                  <button className="findyourrace_card_know_more">
                    Coming Soon
                  </button>
                </div>
              )}
              {!item.showComingSoon && (
              <div className="findyourrace_card_btns">
                <button
                  className="findyourrace_card_know_more"
                  onClick={() => navigate(`/${item.knowMoreLink}`)}
                >
                  Know More
                </button>
                <button
                  className="findyourrace_card_register"
                  onClick={() => handleRegisterNowClick(item.link)}
                >
                  Register Now
                </button>
              </div>)}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default FindYourRaceCard;
