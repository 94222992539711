import React, { useState } from "react";
import Select from "react-select";
import "../train/train.css";
import PageHead from "../common/pageHead/pageHead";
import BannerImg from "../../assets/train/img3.webp";
import NearMeOutlinedIcon from "@mui/icons-material/NearMeOutlined";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
const Train = () => {
  const gyms = [
    {
      id: 1,
      name: "The XL Gym, Sangli",
      address: "Kisan Chowk, Income Tax Road, Opposite Hotel Devgiri, Sangli, Maharashtra 416416",
      city: "Pune",
      lat: 16.85095444710734,
      lng: 74.58710750379973,
      gym_owner: "Deepali Sinhasane",
      contactNumber: "8686152777",
      img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/devil_circuit/xl+gym.jpg"

    },
    {
      id: 2,
      name: "H-One Fitness",
      address: "902, Road No. 47, Opp. CGR International School, Ayyappa Society, Mega Hills, Madhapur, Hyderabad, Telangana 500081",
      city: "Hyderabad",
      lat: 17.448870804523626,
      lng: 78.39253905776195,
      gym_owner: "Bharat",
      contactNumber: "9440334275",
      img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/devil_circuit/hone.jpg"
    },
    {
      id: 3,
      name: "Ninja X",
      address: "  C-36, Hauz Khas, Delhi 110016",
      city: "Delhi",
      lat: 28.54999151961986,
      lng: 77.20652359110879,
      gym_owner: "Nikhil Chauhan",
      contactNumber: "8368195274",
      img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/devil_circuit/ninjax.jpeg"
    },
    {
      id: 4,
      name: "CrossFit HITEC City",
      address: "Gate 2, East Wing, First Floor, ITPH Block A, Capitaland, Inorbit Mall Road, Madhapur, Hyderabad 500081",
      city: "Hyderabad",
      lat: 17.43545220615922,
      lng: 78.38324846880367,
      gym_owner: "Akshit",
      contactNumber: "9811176700",
      img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/devil_circuit/cfithitec1.jpg"
    },
    {
      id: 5,
      name: "Nexgen Fitness Studio",
      address: "6-14/23, Kistamma Enclave Road, Kistamma Enclave, Alwal, Secunderabad, Telangana 500015",
      city: "Hyderabad",
      lat: 17.499281673389582,
      lng: 78.49591538963925,
      gym_owner: "Naveen Reddy",
      contactNumber: "9966651328",
      img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/devil_circuit/nexgen.jpg"
    },
    {
      id: 6,
      name: "Fitlean Fitness",
      address: "V.V. Plaza, Plot No. 64/65, 3rd Floor, Gate No. 1, Opposite NSUT College, Shiv Park, Kakrola, New Delhi, Delhi 110078",
      city: "Delhi",
      lat: 28.610172994963502,
      lng: 77.03385068742578,
      gym_owner: "Chanda",
      contactNumber: "7827470522",
      img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/devil_circuit/fitlean.jpg"
    },
    {
      id: 7,
      name: "Atra Sports Science",
      address: "8-B, 3rd Main Road, Vijayanagar, Velachery, Chennai, Tamil Nadu 600042",
      city: "Chennai",
      lat: 12.972739241572286,
      lng: 80.26575169005119,
      gym_owner: "Rudresh",
      contactNumber: "9176756634",
      img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/devil_circuit/atra1.jpg"
    },
    {
      id: 8,
      name: "Squatz and Lungez",
      address: "No 60/54 Unitower, A6, 3rd Floor, Panampilly Nagar, Ernakulam Kerala 682036",
      city: "Kochi",
      lat: 9.954761850601324,
      lng: 76.29628703430147,
      gym_owner: "Vishnu",
      contactNumber: "8848435843",
      img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/devil_circuit/sqautzandlungez.jpg"
    },
    {
      id: 9,
      name: "Nucleus Fitness",
      address: "S No 57, Mayfield Estates, Behind Mio Pallazio Society, Off Dholepatil Farms Road, Tulaja Bhawani Nagar, Kharadi, Pune, Maharashtra 411014",
      city: "Pune",
      lat: 18.560102206753346,
      lng: 73.95076785786608,
      gym_owner: "Anne Thomas",
      contactNumber: "9373319078",
      img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/devil_circuit/nucleus.png"
    },
    {
      id: 10,
      name: "Orangetheory Fitness, Worli",
      address: "Unit No. 4, 3rd Floor, Trade View, Utopia City, Worli, Mumbai, Maharashtra 400013",
      city: "Mumbai",
      lat: 19.0057810941119,
      lng: 72.82711564594695,
      gym_owner: "Atharva",
      contactNumber: "9892661800",
      img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/devil_circuit/orangetheory.jpg"
    },
    {
      id: 11,
      name: "Orangetheory Fitness, Kemps Corner",
      address: "3rd Floor, Plot 139, B Wing, B - 3A to 3F, Residential & Shopping Complex, August Kranti Marg, Kemps Corner, Mumbai, Maharashtra 400036",
      city: "Mumbai",
      lat: 18.964092032278064,
      lng: 72.80628757902672,
      gym_owner: "Tejas",
      contactNumber: "9082912731",
      img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/devil_circuit/orangetheory.jpg"
    },
    {
      id: 12,
      name: "Orangetheory Fitness, Banjara Hills",
      address: " 3rd Floor, 8-2-547, The Hill Top 7, Road No. 7, Banjara Hills, Hyderabad, Telangana 500034",
      city: "Hyderabad",
      lat: 17.419747435837632,
      lng: 78.44069163653593,
      gym_owner: "Prateek",
      contactNumber: "8655702184",
      img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/devil_circuit/orangetheory.jpg"
    },
    {
      id: 13,
      name: "Fionis CrossFit",
      address: "Old Mahabalipuram Rd, Beside Gokulam Sabari OMR, Egattur, Navalur, Tamil Nadu 603103",
      city: "Chennai",
      lat: 12.84193436765369,
      lng: 80.22739728650795,
      gym_owner: "Anu",
      contactNumber: "9884051175",
      img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/devil_circuit/fioniscrossfit.jpg"
    },
    {
      id: 14,
      name: "Prime Strength",
      address: "3rd Floor, Above Paradise Biryani, Ruby Block, Brundavan Colony, Kompally, Hyderabad, Telangana 500100",
      city: "Hyderabad",
      gym_owner: "Yashwanth",
      contactNumber: "9642211699",
      img:"https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/primestrength.jpg",
      lat: 17.526293794367046,
      lng: 78.4853149187844
      
  },
  {
      id: 15,
      name: "Combat Kinetics Arena Mount Road",
      address: "No. 770-A, 2nd Floor, Dewa Tower – I, Anna Salai, Chennai, Tamil Nadu 600002",
      city: "Chennai",
      gym_owner: "Jay",
      contactNumber: "8531003653",
      img:"https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/CombatKineticsArenaMountRoad.jpg",
      lat: 13.06244247899772,
      lng: 80.26243539090922
       
  },
  {
      id: 16,
      name: "Combat Kinetics Vanagaram",
      address: "Plot No 25, 26, 27, 1/13 Pillaiyar Koil Street, Kuppasamy Nagar, Vanagaram, Chennai, Tamil Nadu 600095",
      city: "Chennai",
      gym_owner: "Jay",
      contactNumber: "8531003653",
      img:"https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/Screenshot+2025-02-06+181300.png",
      lat: 13.054744571090446,
      lng: 80.1491968851811
       
  },
  {
      id: 17,
      name: "Combat Kinetics Velachery",
      address: "Phoenix Market City, Velachery Main Rd, Indira Gandhi Nagar, Velachery, Chennai, Tamil Nadu 600042",
      city: "Chennai",
      gym_owner: "Jay",
      contactNumber: "8531003653",
      img:"https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/Screenshot+2025-02-06+173757-min.png",
      lat: 12.991417596423336,
      lng: 80.21663062883624
       
  },
  {
      id: 18,
      name: "Combat Kinetics Anna Nagar",
      address: "B-25 Second Avenue, Anna Nagar East, Chennai, Tamil Nadu 600102",
      city: "Chennai",
      gym_owner: "Jay",
      contactNumber: "8531003653",
      img:"https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/WhatsApp+Image+2025-02-06+at+5.46.08+PM+(1).jpeg",
      lat: 13.084402353422146,
      lng: 80.22184405243631
       
  },
  {
      id: 19,
      name: "Combat Kinetics Neelankarai",
      address: "#4/314, 2nd Main Rd, CLRI Nagar, Saraswathi Nagar, Neelankarai, Chennai, Tamil Nadu 600041",
      city: "Chennai",
      gym_owner: "Jay",
      contactNumber: "8531003653",
      img:"https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/WhatsApp+Image+2025-02-06+at+5.50.15+PM.jpeg",
      lat: 12.946903450054494,
      lng: 80.25348377459653
       
  },
  {
      id: 20,
      name: "Combat Kinetics Teynampet",
      address: "#30, Cenotaph Road, Teynampet, Chennai, Tamil Nadu 600018",
      city: "Chennai",
      gym_owner: "Jay",
      contactNumber: "8531003653",
      img:"https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/Combat+Kinetics+Teynampet.jpg",
      lat: 13.031217517133454,
      lng: 80.24568475645968
       
  },
  {
      id: 21,
      name: "Combat Kinetics Pallikaranai",
      address: "No 6, 2nd Cross Street, Sri Ambal Nagar, Pallikaranai, Chennai, Tamil Nadu 600100",
      city: "Chennai",
      gym_owner: "Jay",
      contactNumber: "8531003653",
      img:"https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/WhatsApp+Image+2025-02-06+at+5.53.34+PM.jpeg",
      lat: 12.935663466992903,
      lng: 80.1989090432868
       
  },
  {
      id: 22,
      name: "Combat Kinetics Porur",
      address: "One Paramount, RMZ Software Park, 110, Mount Poonamallee Rd, Porur, Chennai, Tamil Nadu 600125",
      city: "Chennai",
      gym_owner: "Jay",
      contactNumber: "8531003653",
      img:"https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/WhatsApp+Image+2025-02-06+at+5.53.35+PM.jpeg",
      lat: 13.031019757722829,
      lng: 80.16824006172094
       
  },
  {
      id: 23,
      name: "Anytime Fitness Mohali",
      address: "SCO No. 42, 43, 44 & 45 1st Floor, District One, Adjoining MC Building, Mohali, Punjab 160062",
      city: "Mohali",
      gym_owner: "Lalit",
      contactNumber: "9875999079",
      img:"https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/Screenshot+2025-02-06+180034-min.png",
      lat: 30.681050394682007,
      lng: 76.72350895768969,
       
  },
  {
      id: 24,
      name: "Anytime Fitness Dugri, Ludhiana",
      address: "SCO 90-91, 2nd Floor, Phase-1 Urban Estate, Dugri, Ludhiana, Punjab 141013",
      city: "Ludhiana",
      gym_owner: "Gagan",
      contactNumber: "7087370070",
      img:"https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/WhatsApp+Image+2025-02-06+at+6.01.47+PM.jpeg",
      lat: 30.87492590182315,
      lng: 75.84010186359872
       
  },
  {
      id: 25,
      name: "Anytime Fitness Civil Lines, Ludhiana",
      address: "2nd and 3rd Floor, B-19-400/2 Rani Jhansi Road, Civil Lines, Ludhiana, Punjab 141010",
      city: "Ludhiana",
      gym_owner: "Gagan",
      contactNumber: "7087370070",
      img:"https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/WhatsApp+Image+2025-02-06+at+6.09.02+PM.jpeg",
      lat: 30.91032024216446,
      lng: 75.8371903201104
       
  },
  {
      id: 26,
      name: "Anytime Fitness Sector 32, Ludhiana",
      address: "3rd Floor, SCO 33, Sector 32, Chandigarh Rd, Sector 32A, Ludhiana, Punjab 141010",
      city: "Ludhiana",
      gym_owner: "Gagan",
      contactNumber: "7087370070",
      img:"https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/Screenshot+2025-02-06+180926.png",
      lat: 30.905539434290755,
      lng: 75.8956268000001
       
  },
  {
      id: 27,
      name: "Anytime Fitness Sector 17, Chandigarh",
      address: "SCO. No - 98-99-100, 3rd floor, Sector 17D, Chandigarh 160017",
      city: "Chandigarh",
      gym_owner: "Gagan",
      contactNumber: "7087370070",
      img:"https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/Anytime+Fitness+Sector+17%2C+Chandigarh.jpg",
      lat: 30.741212863156896,
      lng: 76.78104502091205
      
  },
  {
      id: 28,
      name: "Anytime Fitness Zirakpur",
      address: "1st Floor, Apple’s Height, VIP Road, Zirakpur, Punjab 140603",
      city: "Zirakpur",
      gym_owner: "Gaurav",
      contactNumber: "9878362436",
      img:"https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/2024-06-28.jpg",
      lat: 30.637613626145804,
      lng: 76.81356704637771,
       
  },
  {
      id: 29,
      name: "Anytime Fitness Jalandhar",
      address: "2nd & 3rd Floor, 461, MKC Mall, New Jawahar Nagar, Jalandhar, Punjab 144004",
      city: "Jalandhar",
      gym_owner: "Gagan",
      contactNumber: "7087370070",
      img :"https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/Anytime+Fitness+Jalandhar.jpg",
      lat: 31.31605944836936,
      lng: 75.58112642883619
       
  }
  ];

  const uniqueCities = [...new Set(gyms.map((gym) => gym.city))];
  const cityOptions = uniqueCities.map((city) => ({
    value: city,
    label: city,
  }));

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCity, setSelectedCity] = useState(null);

  const filteredGyms = gyms.filter((gym) => {
    const matchesName = gym.name
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    const matchesCity = selectedCity ? gym.city === selectedCity.value : true;
    return matchesName && matchesCity;
  });

  const customStyles = {
    valueContainer: (provided, state) => ({
      ...provided,
      padding: "2px 0",
    }),
    indicatorSeparator: (provided, state) => ({
      display: "none",
    }),
    control: (provided) => ({
      ...provided,
      color: "white",
      borderRadius: "6px",
      border: "none",
      boxShadow: "none",
      "&:focus": {
        border: "none",
        boxShadow: "none",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#fff",
      color: "#000",
      border: "none",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "#fff",
      color: "#000",
      border: "none",
      "&:hover": {
        backgroundColor: "#fff",
        color: "#000",
      },
    }),
  };

  const handleCardClick = (gym) => {
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${gym.lat},${gym.lng}`;
    window.open(googleMapsUrl, "_blank");
  };
  const truncateAddress = (address, maxLength = 500) => {
    if (!address) return "";
    return address.length > maxLength
      ? address.slice(0, maxLength) + "..."
      : address;
  };

  return (
    <div>
      <PageHead
        page_name="Train"
        page_head="Train"
        text_1="We know you’re eager to conquer Maruti Suzuki Arena Devils Circuit and thus, we’ve partnered with gyms across the country to help you to become the strongest version of yourself! With their guidance and tailored training programs, you will not only conquer the course but also transform your normal and emerge better than ever."
        media={BannerImg}
        media_type="image"
      />

      <div className="train_cards_main_div">
        <div className="train_cards_header_div">
          <p>Find your Gym</p>
          <div className="train_cards_header_underline"></div>
        </div>

        <div className="train_filters">
          <div className="train_search_div">
            <label htmlFor="search_name">Search</label>
            <input
              type="text"
              id="search_name"
              placeholder="Gym Name"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="search-bar"
            />
          </div>

          <div className="train_search_div">
            <label htmlFor="city">Select City</label>
            <Select
              id="city"
              options={cityOptions}
              value={selectedCity}
              onChange={setSelectedCity}
              placeholder="Select city"
              isClearable
              className="train_city_select"
              styles={customStyles}
              isSearchable={false}
            />
          </div>
        </div>

        <div className="train_cards_div">
          <div className="train_cards_inner_div">
            {filteredGyms.map((gym) => (
              <div key={gym.id} className="train_card">
                <div className="train_card_img_div">
                  <img src={gym.img} alt="img" />
                </div>
                <div className="train_card_info_div">
                  <p className="train_card_name">{gym.name}</p>
                  <p className="train_card_contact">{gym.gym_owner}</p>
                  <p className="train_card_contact">
                    <PhoneIcon sx={{ marginLeft: "-5px", height: "20px" }} /> {gym.contactNumber}
                  </p>
                  <p className="train_card_address">
                    <LocationOnIcon sx={{ marginLeft: "-5px", height: "20px" }} />
                    {truncateAddress(gym.address)}{" "}
                    <span onClick={() => handleCardClick(gym)}>
                      {" "}
                      direction{" "}
                      <NearMeOutlinedIcon className="train_card_address_icon" />
                    </span>{" "}

                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Train;
