import React from 'react';
import './getcard.css';

const GetCard = () => {
  const cardData = [
    {
      title: "MEDAL",
      image:"https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/Group+1000007346.png",
      description: "Earn your glory; flaunt your win with the legendary Devilslayer medal."
    },
    {
      title: "GOODY BAG",
      image:"https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/Group+1000007350.png",
      description: "New Year. New Surprise. Discover what's in yours this season"
    },
    {
      title: "T-SHIRT",
      image:"https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/Group+1000007345.png",
      description: "Join the tribe in style with the iconic Devilslayer t-shirt."
    },
    {
      title: "ARM SLEEVE",
      image:"https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/Arm+Sleeves.webp",
      description: "The Devilslayer arm sleeves are making a comeback, just for you"
    },
    {
      title: "BANDANA",
      image:"https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/Group+1000007349.png",
      description: "Your bandana, your identity. Can't be bought, must be earned."
    },
    {
      title: "Water",
      image:"https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/Group+1000007343.png",
      description: "Stay refreshed with hydration stations at every kilometer."
    },
    {
      title: "Energy Drink",
      image:"https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/Group+1000007344.png",
      description: "Celebrate your finish by refueling with an energizing drink."
    },
    {
      title: "Photographs",
      image:"https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/Photograph.webp",
      description: "Your best moments, professionally captured and free to download."
    }
    
  ];

  return (
    <div className="getcard-container-get">
      <h1 className="getcard-heading-get">WHAT YOU <span>GET!</span></h1>
      
      <div className="cards-container-get">
        {cardData.map((card, index) => (
          <div className="card-get" key={index}>
            <img src={card.image} alt={card.title} />
            <div className="card-content-get">
              <h2>{card.title}</h2>
              <p>{card.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GetCard;
