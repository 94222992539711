import { lazy, Suspense, useEffect, useState } from "react";
import "./homepage.css";
import prize from "../../assets/homepage/prize.png";
import MovingBanner from "../common/movingBanner/movingBanner";
import BannerVideo from "./BannerVideo";
import InstaFeed from "./InstaPost";
import TopSlider from "./TopSlider";
import FindRace from "../common/findRaceFooter/findrace";
import band from "../../assets/homepage/newband.png";
import tag from "../../assets/homepage/tag.png";
import Proud from "./proud/Proud";
import Sponsor from "./sponsor/Sponsor";
import MobileSponsors from "./sponsor/MobileSponsor";
import useUtmParams from "../../utils/useUtmParams";
import NewSeason from "../newSeason/newseason";

const City = lazy(() => import("./City"));
const Playground = lazy(() => import("./playground"));
const CityCard = lazy(() => import("./CityCard"));
const Slider = lazy(() => import("./Slider"));

const HomePage = () => {
  useUtmParams();
  const [isMobile, setIsMobile] = useState(false);


  useEffect(() => {
    console.log("hello");
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);

    return () => {
      window.removeEventListener('resize', checkMobile);
    };
  }, []);


  return (
    <div className="homepage-container">
      <NewSeason/>
        <Proud />
        {isMobile ? <MobileSponsors /> : <Sponsor />}
    </div>
  );
};

export default HomePage;
