import React from "react";
import "./findracecard.css";
import lucknow from "../../assets/lucknow.svg";
import backg from "../../assets/backg.svg";
import { useNavigate } from "react-router-dom";

const FindRaceCard = () => {
  const navigate = useNavigate();

  const races = [
    {
      city: "SRINAGAR",
      date: "AUGUST 31, 2025",
      knowMoreLink: "new-city-page/Delhi",
      link: "https://getmybib.com/157/register",
      showcomingsoon: true,
      image:
        "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/image+5-9.png",
    },
    {
      city: "LUCKNOW",
      date: "SEPTEMBER 14, 2025",
      knowMoreLink: "new-city-page/Delhi",
      link: "https://getmybib.com/157/register",
      showcomingsoon: true,
      image: lucknow,
    },
    {
      city: "INDORE",
      date: "SEPTEMBER 28, 2025",
      knowMoreLink: "new-city-page/Indore",
      link: "https://getmybib.com/231/register?ename=Maruti-Suzuki-Arena-Devils-Circuit-Indore-2025-26",
      showcomingsoon: false,
      image:
        "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/image+5-5.png",
    },
    {
      city: "AHMEDABAD",
      date: "OCTOBER 12, 2025",
      knowMoreLink: "new-city-page/Delhi",
      link: "https://getmybib.com/157/register",
      showcomingsoon: true,
      image:
        "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/image+5-6.png",
    },
    {
      city: "PUNE",
      date: "November 16, 2025",
      knowMoreLink: "new-city-page/Delhi",
      link: "https://getmybib.com/157/register",
      showcomingsoon: true,
      image:
        "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/rectangle-462-668912806eb5d+1.png",
    },
    {
      city: "MUMBAI",
      date: "NOVEMBER 30, 2025",
      knowMoreLink: "new-city-page/Mumbai",
      link: "https://getmybib.com/236/register?ename=Maruti-Suzuki-Arena-Devils-Circuit-Mumbai-2025-26",
      showcomingsoon: false,
      image:
        "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/image+5-7.png",
    },
    {
      city: "BENGALURU",
      date: "DECEMBER 21, 2025",
      knowMoreLink: "new-city-page/Bengaluru",
      link: "https://getmybib.com/240/register?ename=Maruti-Suzuki-Arena-Devils-Circuit-Bengaluru-2025-26",
      showcomingsoon: false,
      image:
        "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/Vector.png",
    },
    {
      city: "HYDERABAD",
      date: "JANUARY 04, 2026",
      knowMoreLink: "new-city-page/Hyderabad",
      link: "https://getmybib.com/238/register?ename=Maruti-Suzuki-Arena-Devils-Circuit-Hyderabad-2025-26",
      showcomingsoon: false,
      image:
        "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/image+5-1.png",
    },
    {
      city: "KOCHI",
      date: "JANUARY 18, 2026",
      knowMoreLink: "new-city-page/Kochi",
      link: "https://getmybib.com/232/register?ename=Maruti-Suzuki-Arena-Devils-Circuit-Kochi-2025-26",
      showcomingsoon: false,
      image:
        "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/image+5.png",
    },
    {
      city: "CHENNAI",
      date: "FEBRUARY 01, 2026",
      knowMoreLink: "new-city-page/Chennai",
      link: "https://getmybib.com/235/register?ename=Maruti-Suzuki-Arena-Devils-Circuit-Chennai-2025-26",
      showcomingsoon: false,
      image:
        "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/image+5-2.png",
    },
    {
      city: "GUWAHATI",
      date: "FEBRUARY 22, 2026",
      knowMoreLink: "new-city-page/Delhi",
      link: "https://getmybib.com/157/register",
      showcomingsoon: true,
      image:
        "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/image+5-3.png",
    },
    {
      city: "DELHI NCR",
      date: "MARCH 15, 2026",
      knowMoreLink: "new-city-page/Delhi",
      link: "https://getmybib.com/157/register",
      showcomingsoon: true,
      image:
        "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/image+5-4.png",
    },
  ];

  const scrollContainer = (direction) => {
    const container = document.querySelector(".race-cards-container");
    const scrollAmount = 330;
    if (direction === "left") {
      container.scrollBy({
        left: -scrollAmount,
        behavior: "smooth",
      });
    } else {
      container.scrollBy({
        left: scrollAmount,
        behavior: "smooth",
      });
    }
  };
  const handleRegisterNowClick = (url) => {
    const appendUtmParams = (url) => {
      const utmParams = [
        "utm_source",
        "utm_medium",
        "utm_campaign",
        "utm_content",
        "utm_term",
      ]
        .map(
          (key) =>
            sessionStorage.getItem(key) &&
            `${key}=${encodeURIComponent(sessionStorage.getItem(key))}`
        )
        .filter(Boolean)
        .join("&");

      return utmParams ? `${url}?${utmParams}` : url;
    };

    const buttonLinkWithUtm = appendUtmParams(url);
    window.location.href = buttonLinkWithUtm;
  };

  return (
    <div
      className="find-race-container"
      style={{ "--bg-image": `url(${backg})` }}
    >
      <h1>FIND YOUR RACE</h1>
      <div className="race-cards-wrapper">
        <button
          className="scroll-btn scroll-left"
          onClick={() => scrollContainer("left")}
        >
          ❮
        </button>
        <div className="race-cards-container">
          {races.map((race, index) => (
            <div key={index} className="race-card">
              <img src={race.image} alt={race.city} className="race-image" />
              <div className="race-info">
                <h2>{race.city}</h2>
                <p>{race.date}</p>
                {race.showcomingsoon && (
                  <div>
                    <button className="coming-soon-button">Coming soon</button>
                  </div>
                )}
                {!race.showcomingsoon && (
                  <div className="button-group">
                    <button
                      className="know-more-btn-race"
                      onClick={() => navigate(`/${race.knowMoreLink}`)}
                    >
                      Know more
                    </button>
                    <button
                      className="register-btn-race"
                      onClick={() => handleRegisterNowClick(race.link)}
                    >
                      Register Now
                    </button>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
        <button
          className="scroll-btn scroll-right"
          onClick={() => scrollContainer("right")}
        >
          ❯
        </button>
      </div>
    </div>
  );
};

export default FindRaceCard;
