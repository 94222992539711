import React from "react";
import "./playground.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
const Playground = ({ showJunior, category }) => {
  const navigate = useNavigate();

  const handleCompetitiveClick = () => {
    navigate("/categories");
  };
  const handleNonCompetitiveClick = () => {
    navigate("/categories/non_comp");
  };
  const handleJuniorClick = () => {
    navigate("/categories/junior");
  };
  const handleMasterClick = () => {
    navigate("/categories/master");
  };

  return (
    <div className="play-container">
      <h1>{category ? "category" : "Choose Your Playground"}</h1>
      <div className="playground">
        <div className="content-container">
          <div id="box1">
            <p className="play_heading">Competitive</p>
            <div className="content">
              <h2>Competitive</h2>
              <span>
              The Competitive Category is perfect for elite athletes who want more than just one season of excitement and rewards.
              </span>
              <div>
                <button onClick={handleCompetitiveClick}>Know More</button>
              </div>
            </div>
          </div>
          <div id="box2">
            <p className="play_heading">Master</p>
            <div className="content">
              <h2>Master</h2>
              <span>
              Our Masters category lets everyone over 40 compete with peers in a dedicated competition group while sharing the same wave and experience.
              </span>
              <div>
                <button onClick={handleMasterClick}>Know More</button>
              </div>
            </div>
          </div>
          <div id="box3">
            <p className="play_heading">Non Competitive</p>
            <div className="content">
              <h2>Non Competitive</h2>
              <span>
              A category without the burden of competition. The same course and obstacles but without the timing chip.

              </span>
              <div>
                <button onClick={handleNonCompetitiveClick}>Know More</button>
              </div>
            </div>
          </div>
          {/* {showJunior && (
            <div id="box4">
            <p className="play_heading">Junior</p>
            <div className="content">
              <h2>Junior</h2>
              <span>
              This category is created exclusively for kids from the age of 12-16 to experience the thrill of the competition.

              </span>
              <div>
                <button onClick={handleJuniorClick}>Know More</button>
              </div>
            </div>
          </div>
          )} */}
          
        </div>
      </div>

      <div className="card-container">
        <div className="card-outerdiv">
          <Link to="/categories">
            <div className="card-overlay">
              <div className="name-heading">Competitive</div>
              <div className="name-desc">
              The Competitive Category is perfect for elite athletes who want more than just one season of excitement and rewards.
              </div>
              <button className="card-button">View Details</button>
            </div>
            <img
              src="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/PG.webp"
              alt="Card Image 1"
            />
          </Link>
        </div>
        <div className="card-outerdiv">
          <Link to="/categories/master">
            <div className="card-overlay">
              <div className="name-heading">Master</div>
              <div className="name-desc">
              Our Masters category lets everyone over 40 compete with peers in a dedicated competition group while sharing the same wave and experience.
              </div>
              <button className="card-button">View Details</button>
            </div>
            <img
              className="cardImage"
              src="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/devil_circuit_finalimages/master+(3).webp"
              alt="Card Image 4"
            />
          </Link>
        </div>
        <div className="card-outerdiv">
          <Link to="/categories/non_comp">
            <div className="card-overlay">
              <div className="name-heading">Non Competitive</div>
              <div className="name-desc">
              A category without the burden of competition. The same course and obstacles but without the timing chip.

              </div>
              <button className="card-button">View Details</button>
            </div>
            <img
              src="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/PG+(1).webp"
              alt="Card Image 2"
            />
          </Link>
        </div>
        {/* {showJunior && (
          <div className="card-outerdiv">
          <Link to="/categories/junior">
            <div className="card-overlay">
              <div className="name-heading">Junior</div>
              <div className="name-desc">
              This category is created exclusively for kids from the age of 12-16 to experience the thrill of the competition.

              </div>
              <button className="card-button">View Details</button>
            </div>
            <img
              src="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcunder500/Rectangle+478.jpg"
              alt="Card Image 3"
            />
          </Link>
        </div>
        )} */}
        
      </div>
    </div>
  );
};
export default Playground;
