import { useEffect } from "react";

const useUtmParams = () => {
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const utmSource = searchParams.get("utm_source");
    const utmMedium = searchParams.get("utm_medium");
    const utmCampaign = searchParams.get("utm_campaign");
    const utmContent = searchParams.get("utm_content");
    const utmTerm = searchParams.get("utm_term");

    if (utmSource && !sessionStorage.getItem("utm_source")) {
      sessionStorage.setItem("utm_source", utmSource);
    }
    if (utmMedium && !sessionStorage.getItem("utm_medium")) {
      sessionStorage.setItem("utm_medium", utmMedium);
    }
    if (utmCampaign && !sessionStorage.getItem("utm_campaign")) {
      sessionStorage.setItem("utm_campaign", utmCampaign);
    }
    if (utmContent && !sessionStorage.getItem("utm_content")) {
      sessionStorage.setItem("utm_content", utmContent);
    }
    if (utmTerm && !sessionStorage.getItem("utm_term")) {
      sessionStorage.setItem("utm_term", utmTerm);
    }
  }, []);
};

export default useUtmParams;