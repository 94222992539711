// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.newseason-container {
  width: 100%;
  overflow-x: hidden;
}

.page-video-tag {
  width: 100%;
  height: auto;
  object-fit: cover;
}

/* Mobile Responsive Styles */
@media (max-width: 768px) {
  .newseason-container {
    padding: 0;
  }
  
  /* .page-video-tag {
    height: 300px;
  } */
}
`, "",{"version":3,"sources":["webpack://./src/components/newSeason/newseason.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;AACnB;;AAEA,6BAA6B;AAC7B;EACE;IACE,UAAU;EACZ;;EAEA;;KAEG;AACL","sourcesContent":[".newseason-container {\n  width: 100%;\n  overflow-x: hidden;\n}\n\n.page-video-tag {\n  width: 100%;\n  height: auto;\n  object-fit: cover;\n}\n\n/* Mobile Responsive Styles */\n@media (max-width: 768px) {\n  .newseason-container {\n    padding: 0;\n  }\n  \n  /* .page-video-tag {\n    height: 300px;\n  } */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
