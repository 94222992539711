import React, { useState } from 'react';
import './warrior.css';
import warriorbg from '../../assets/warriorbge.webp';
import { useNavigate } from "react-router-dom";


const VolumeIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 5L6 9H2V15H6L11 19V5Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M15.54 8.46C16.4774 9.39764 17.0039 10.6692 17.0039 12C17.0039 13.3308 16.4774 14.6024 15.54 15.54" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M19.07 4.93C20.9447 6.80528 21.9979 9.34836 21.9979 12C21.9979 14.6516 20.9447 17.1947 19.07 19.07" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const MuteIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 5L6 9H2V15H6L11 19V5Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M23 9L17 15" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M17 9L23 15" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const PlayIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 3L19 12L5 21V3Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const PauseIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 4H10V20H6V4Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M14 4H18V20H14V4Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const Warrior = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [isVideoPaused, setIsVideoPaused] = useState(false);
  const videoRef = React.useRef(null);
  const navigate = useNavigate();

  const handlePlayClick = () => {
    setIsPlaying(true);
  };

  const toggleMute = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsMuted(!isMuted);
  };

  const togglePlayPause = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
        setIsVideoPaused(false);
      } else {
        videoRef.current.pause();
        setIsVideoPaused(true);
      }
    }
  };

  const handleVideoEnd = () => {
    setIsPlaying(false);
    setIsVideoPaused(false);
  };

  return (
    <div 
      className="warrior-container"
      style={{ '--bg-image': `url(${warriorbg})` }}
    >
      <div className="warrior-content">
        <div className="warrior-text">
          <h1>
            OUR<br />
            CORPORATE<br />
            WARRIORS
          </h1>
          <p className="description">
            Companies that prioritize health, engagement, and collaboration see 
            higher productivity and lower attrition — and that's exactly what Devils 
            Circuit brings to the table.
          </p>
          <p className="companies">
            Top companies like Maruti Suzuki, Awfis, Zomato, Zerodha, and hundreds 
            more return to this challenge year after year. Find out why!
          </p>
          <button className="know-more-btn" onClick={() => navigate("/corporate")}>
      Know more!
    </button>          </div>
        <div className="warrior-images">
          <div className="main-image" style={{ display: isPlaying ? 'none' : 'block' }}>
            <div className="play-button" onClick={handlePlayClick}>
              <span>▶</span>
            </div>
          </div>
          {isPlaying && (
            <>
              <video 
                ref={videoRef}
                className="main-image"
                autoPlay
                playsInline
                muted={isMuted}
                onEnded={handleVideoEnd}
                src="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/website+Corporate+.mp4"
              />
              <button 
                className="play-pause-button" 
                onClick={togglePlayPause}
              >
                {isVideoPaused ? <PlayIcon /> : <PauseIcon />}
              </button>
              <button 
                className="mute-button" 
                onClick={toggleMute}
              >
                {isMuted ? <MuteIcon /> : <VolumeIcon />}
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Warrior;
