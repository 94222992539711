import PageHead from "../common/pageHead/pageHead";
import { data } from '../../../src/assets/cateories/data';
import obstacle from '../../assets/village/divider.svg';
import '../gym/gym.css';
import { useState } from "react";

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import band from "../../assets/homepage/newband.png";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";

const options = [
    { value: 'delhi', label: 'Delhi' },
    { value: 'goa', label: 'Goa' },
    { value: 'mumbai', label: 'Mumbai' },
    { value: 'pune', label: 'Pune' },
]
function GYM() {
    const [selectedValue, setSelectedValue] = useState("Delhi");
    const [selectedOption, setSelectedOption] = useState("delhi");
    const [selected, setSelected] = useState([{ value: 'delhi', label: 'Delhi' },]);

    const [activeCity, setactiveCity] = useState("delhi");

    const [spinState, setSpinState] = useState(false);

  const validationSchema = Yup.object().shape({
    fullName: Yup.string()
      .min(2, "Name should be atleast 2 characters")
      .max(100, "Name should be less 100 characters")
      .required("Name is required"),
    Email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    Mobile: Yup.string()
    .nullable()
    .required("Mobile number is required")
    .matches(/^[0-9]{10}$/, "Mobile Number must be a 10-digit number"),
    GymName: Yup.string()
      .min(2, "Gym name should be atleast 2 characters")
      .max(100, "Gym name should be less 100 characters"),
      // .required("Gym name is required"),
    City: Yup.string()
      .min(2, "City should be atleast 2 characters")
      .max(100, "City should be less 100 characters"),
      // .required("City is required"),
  });

  const initialValues = {
    fullName: "",
    Email: "",
    Mobile: "",
    GymName: "",
    City: "",
  };

  const handleSubmit = async (values) => {
    setSpinState(true);

    try {
      await axios.post("https://submit-form.com/KRABmYsYn", values);
      setSpinState(false);
      toast.success("Message submited successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } catch (error) {
      setSpinState(false);
      console.error("Error submitting form:", error);
      toast.warn("Something went wrong, Please try again later.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

    const handleChange = (selected) => {
        setSelected(selected);
        setSelectedValue(selected.label);
        setSelectedOption(selected.value);
        setactiveCity(selected.value);
    };

    return (
        <>
            <PageHead
                page_name={data.gym[0].heading}
                text_1={data.gym[0].gym}
                media={data.gym[0].image_url}
                media_type="image"
            />
            <div className="gymContainer">
                <h1>{data.gym[1].subheading[0].heading}</h1>
                <ul>
                    <li>{data.gym[1].subheading[0].text1}</li>
                    <li>{data.gym[1].subheading[0].text2}</li>
                    <li>{data.gym[1].subheading[0].text3}</li>
                    <li>{data.gym[1].subheading[0].text4}</li>
                </ul>
                <div className="lotmore">{data.gym[1].subheading[0].text5}</div>
                <p>{data.gym[1].subheading[0].text6} <span>{data.gym[1].subheading[0].email}</span> {data.gym[1].subheading[0].text7}</p>
            </div>
            {/* <div style={{ backgroundColor: '#262626' }}>
                <div className="gymContainerDark">
                    <h1>Find Gym In Your City</h1>
                    <div className="gymBorder"></div>
                    <p htmlFor="uncontrolled-native" style={{ color: '#fff' }}>
                        Select City
                    </p>
                    <Select
                        isSearchable={false}
                        value={selected}
                        onChange={handleChange}
                        options={options}

                        styles={{
                            control: (provided) => ({
                                ...provided,
                                width: '350px',
                                borderRadius: '0px',
                                border: '1px solid #aeaeae', // Remove the border
                                boxShadow: 'none', // Remove the box shadow
                                '&:focus': {
                                    border: '1px solid #aeaeae', // Remove the border on focus
                                    boxShadow: 'none', // Remove the box shadow on focus
                                },
                            }),
                            menu: (provided) => ({
                                ...provided,
                                width: '350px', // Set the width of the dropdown list
                            }),
                        }}
                    />
                </div>
                <div className="cardsContainer">
                    {activeCity === "delhi" &&
                        <div className="gymCard">
                            <img src="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/devil_circuit/gymcard.png" alt="card" />

                            <h1>Fitness Experts</h1>
                            <div>
                                <StarIcon />
                                <h1>4.8 (900)</h1>
                            </div>
                            <p>Lorem ipsum dolor sit amet consectetur. Varius imperdiet scelerisque suspendisse id. Arcu vel amet scelerisque lacus. Sed tellus mi gravida egestas eu.</p>
                            <button>View Location</button>
                        </div>
                    }
                    {activeCity === "goa" &&
                        <div className="gymCard">
                            <img src="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/devil_circuit/gymcard.png" alt="card" />
                            <h1>Fitness Experts</h1>
                            <div>
                                <StarIcon />
                                <h1>4.8 (900)</h1>
                            </div>
                            <p> imperdiet scelerisque suspendisse id. Arcu vel amet scelerisque lacus. Sed tellus mi gravida egestas eu.</p>
                            <button>View Location</button>

                        </div>
                    }
                </div>
            </div> */}
            {/* <GymLocator/> */}

           <div className="gym_form_header">
            <p>Become a Partner</p>
            <p>Give us your details to start a beautiful friendship </p>
           </div>

            <div className="contact_form_main_div">
          <div className="contact_form_div"> 
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting }) => {
                handleSubmit(values);
              }}
            >
              {({ isSubmitting, handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="contact_form">
                    <div className="contact_field_box">
                      <label htmlFor="fullName">Name*</label>
                      <Field
                        type="text"
                        id="fullName"
                        name="fullName"
                        className="contact_field"
                      />
                      <ErrorMessage
                        name="fullName"
                        component="div"
                        className="contact_invalid_feedback"
                      />
                    </div>

                    <div className="contact_field_box">
                      <label htmlFor="Email">Email*</label>
                      <Field
                        type="email"
                        id="Email"
                        name="Email"
                        className="contact_field"
                      />
                      <ErrorMessage
                        name="Email"
                        component="div"
                        className="contact_invalid_feedback"
                      />
                    </div>

                    <div className="contact_field_box">
                      <label htmlFor="Mobile">Mobile*</label>
                      <Field
                        type="number"
                        id="Mobile"
                        name="Mobile"
                       maxLength="10"
                        className="contact_field"
                      />
                      <ErrorMessage
                        name="Mobile"
                        component="div"
                        className="contact_invalid_feedback"
                      />
                    </div>

                    <div className="contact_field_box">
                      <label htmlFor="GymName">Gym Name</label>
                      <Field
                        type="text"
                        id="GymName"
                        name="GymName"
                        className="contact_field"
                      />
                      <ErrorMessage
                        name="GymName"
                        component="div"
                        className="contact_invalid_feedback"
                      />
                    </div>

                    <div className="contact_field_box">
                      <label htmlFor="City">City</label>
                      <Field
                        type="text"
                        id="City"
                        name="City"
                        className="contact_field"
                      />
                      <ErrorMessage
                        name="City"
                        component="div"
                        className="contact_invalid_feedback"
                      />
                    </div>

                  </div>

                  <button
                    type="submit"
                    className="contact_join_btn_div"
                    style={{
                      cursor: !spinState ? "pointer" : "none",
                    }}
                  >
                    <div className="contact_join_btn_inner_div">
                      {spinState ? (
                        <CircularProgress
                          className="custom-progress"
                          style={{ color: "white" }}
                          size={25}
                          thickness={3}
                        />
                      ) : (
                        <span>Submit</span>
                      )}
                    </div>
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>

        {/* <div className="band_img_div_gym">
          <img src={band} alt="band" loading="lazy" />
        </div> */}
            {/* <div className="backgroundBanner" style={{ backgroundImage: `url(${obstacle})` }}>
            </div> */}

<ToastContainer />
        </>
    )
}
export default GYM;